<template>
	<div class="select_page">
		<div class="top_tip">
			<img src="@/assets/img/tips-icon.png" alt="" class="img" />
			<div class="tip_txt">
				1. 以下为客户所推荐的代理IP平台，官方仅做整理展示，服务与质量请自行判断，官方不做保证和承担责任！所有代理IP使用相关问题请咨询平台方客服！<br />
				2. 免责声明：请合法合规使用，若因违规使用产生一切后果自行负责，与本软件官方无关！
			</div>
		</div>
		<a href="https://www.smartproxy.cn/?keyword=ytllq" target="_blank" class="banner banner_1"></a>
		<a href="https://www.stormproxies.cn/?keyword=ytllq" target="_blank" class="banner banner_2"></a>
	</div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
@import "@/style/mixin.less";
.select_page {
	padding: 18px 18px 18px 58px;
	.top_tip {
		padding: 8px 16px;
		background: #fff9f2;
		border-radius: 3px;
		border: 1px solid #ed7b2f;
		.flex(flex-start);
		.img {
			width: 20px;
			margin-right: 20px;
		}
		.tip_txt {
			font-size: 13px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #374567;
			line-height: 20px;
		}
	}
	.banner {
		display: block;
		width: 100%;
		height: 192px;
		margin-top: 20px;
	}
	.banner_1 {
		background: url("~@/assets/img/smartproxy_bg@2x.png") center/100% 100%;
	}
	.banner_2 {
		background: url("~@/assets/img/storm_bg@2x.png") center/100% 100%;
	}
}
</style>
